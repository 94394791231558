
import React from "react";
import { useTranslation } from "react-i18next"
import HeroImg from "../../components/HeroImg/HeroImg";
import MainLayout from "../../layouts/MainLayout";
import "./how-to-drink.scss";

const HowToDrink = () => {
  const { t } = useTranslation()
  return (
    <MainLayout metaTitle={t("how-to-drink:metaTitle")}>
      <>
        <HeroImg customClass="how-to-drink-hero" subtitle={t("how-to-drink:heroSubtitle")} title={t("how-to-drink:heroTitle")} backgroundImage="bg/how-to-drink-hero.jpg" />

        <div className="how-to-drink-section-two">
          <div className="how-to-drink-section-two-box">
            <h3>{t("how-to-drink:sectionOneTitle")}</h3>
            <p className="other-style">{t("how-to-drink:sectionOneTextOne")}</p>
            <p>{t("how-to-drink:sectionOneTextTwo")}</p>
          </div>
        </div>

        <HeroImg customClass="how-to-drink-middle" backgroundImage="bg/how-to-drink-middle.jpg">
          {<h3 className="how-to-drink-middle-text">{t("how-to-drink:sectionTwoTitle")}</h3>}
        </HeroImg>

        <div className="how-to-drink-section-two">
          <div className="how-to-drink-section-two-box">
            <h3>{t("how-to-drink:sectionThreeTitle")}</h3>
            <p className="other-style">{t("how-to-drink:sectionThreeTextOne")}</p>
            <p>{t("how-to-drink:sectionThreeTextTwo")}</p>
            <p className="box-two"> {t("how-to-drink:sectionThreeTextTwo/two")}</p>
            <p className="box-two">{t("how-to-drink:sectionThreeTextThree")}</p>
            <p className="box-two">{t("how-to-drink:sectionThreeTextFour")}</p>
          </div>

        </div>

        <HeroImg customClass="how-to-drink-bottom" backgroundImage="bg/how-to-drink-bottom.jpg">
          {
            <h3 className="how-to-drink-bottom-text">
              {t("how-to-drink:sectionFourTitle")}
            </h3>
          }
        </HeroImg>
      </>
    </MainLayout>
  )
}

export default HowToDrink;